.searchContainer {
  height: $bodyHeight;
  width: $searchbar-width;
  z-index: 0;
  position: fixed;
  top: $headerHeight;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 25px;
  margin-bottom: 10px;

  background-color: $color-secondary;
  overflow: hidden;
  transition: width 0.5s;

  > * {
    margin: 0 auto;
    width: 85%;
    text-align: center;
  }

  @media screen and (max-width: $tablet) and (min-width: $mobile) {
    width: $searchbar-width--tablet;
  }

  @media screen and (max-width: $mobile) {
    width: $searchbar-width--mobile;
    z-index: 25;
  }
}

.hideSidebar--search {
  width: 0;
}

#searchBar {
  padding: 0;
  background-color: white;
}

#toolbar,
#runButton,
#clearButton {
  max-width: 300px;
  margin: 0 auto;
}

#toolbar {
  text-align: left;
  summary {
    padding-bottom: 5px;
  }
  div {
    font-size: .8em;
    padding-bottom: 2.5px;
  }
}

#runButton, #clearButton {
  .btn {
    width: 100%;
    padding: 7px 15px;
    font-size: 1rem;
    background-color: $color-primary;
  }
}

#clearButton {
  width: 100%;
  margin-bottom: 25px;
}
