
#artistTiles {
  bottom: 0;
  margin: 0 auto;
  margin-bottom: 25px;
  height: auto;
  flex: 1;
  position: relative;
  overflow-y: scroll;
}

.artistTile {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 3px;

  padding: 10px;
  white-space: pre-wrap;
  text-align: left;
  background-color: $color-primary;
  outline: none;
  transition: outline 500ms;

  + .artistTile {
    margin-top: 10px;
  }

  .artistImgBox {
    flex: 1;
    .artistImg {
      width: 100%;
    }
  }
  .artistInfoBox {
    flex: 3;

    p {
      margin-top: .5em;
      margin-bottom: .5em;
    }
  }

  .excerpt {
    font-size: .8em;

    &--text {
      padding-bottom: .5em;
    }

  }

  .highlightBtn {
    height: 100%;
    margin-top: 0px;
    margin-bottom: auto;

    input + label {
      display: none;
    }

    input:hover + label {
      display: inline-block;
      transition: display 500ms;
      position: absolute;
      right: calc(7.5% + 18px);
      width: auto;
      border: 1px solid black;
      background: white;
      font-size: 10px;
      margin-top: -3px;
      padding: 5px;
    }
  }
}

.highlight {
  outline: 3px dashed $blue;
}