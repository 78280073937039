@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url("https://use.typekit.net/uyp8wii.css");

// reset
html {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: $font-body, Arial, -apple-system, 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// header
header {
  height: $headerHeight;
  background-color: $blue;
  
  color: white;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 10;
  border-bottom: 1px solid white;
  
}

// buttons
.button {
  background: none;
  color: white;
  border: 0 none;
  height: 100%;
  display: inline-block;

  h1 {
    font-family: 'opake', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 1.5rem;
    line-height: $headerHeight;
    text-align: center;
    display: inline;
    text-decoration: none;
    height: 100%;

    &:hover {
      text-decoration: underline;
      text-decoration-color: white;
      text-decoration-style: dotted;
      cursor: pointer;
    }
  }

  &--nav {
    font-size: 1.5em;
    font-weight: bold;
    float: right;
    position: absolute;
    right: 0;
    cursor: pointer;
    transform: rotate(90deg);
    
  }
  
}

.link-btn {
  text-align: center;
  position: relative;
  width: 100px;
  background-color: $blue;
  font-weight: bold;
  border: none;
  border-radius: 2.5px;

  a, p {
    display: inline-block;
    padding: 5px;
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
}

// loading dots
.loading-dots {
  text-align: center;
  width: 100%;

  &--black {
    background-color: #000;
  }

  &--white {
    background-color: #FFF;
  }
  
  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    border-radius: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;
    
    &:nth-child(2) {
      animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}