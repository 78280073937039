$font-head: 'Cabin';
$font-body: 'Cabin';
$blue: #1880DB;
$color-primary: #F9F9F9;
$color-secondary: #EBEBEB;

$headerHeight: 50px;
$bodyHeight: calc(100% - 51px);

$searchbar-width: 37.5vw;
$searchbar-width--tablet: 50vw;
$searchbar-width--mobile: 100vw;

// queries
$tablet: 800px;
$mobile: 500px;


