@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

@keyframes tooltipFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}