.popUp {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: grid;
  place-items: center;

  &--window {
    position: relative;
    padding: 15px;
    width: 100%;
    max-width: 600px;
    background-color: white;
    
    > * {
      padding-left: 15px;
      padding-right: 15px;

      @media screen and (max-width: $tablet) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    h3 {
      text-align: center;
    }

    button {
      color: black;
      font-size: 1.5em;
      font-weight: bold;
      float: right;
      position: relative;
      cursor: pointer;
    }
  }
}