.albumTile {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 175px;

  @media screen and (max-width: $tablet) {
    width: 150px;
  }

  .albumCover {
    z-index: 1;
    width: 175px;
    height: 175px;

    @media screen and (max-width: $tablet) {
      width: 150px;
      height: 150px;
    }
  }

  .albumInfo {
    display: none;
    position: absolute;
    z-index: 5;

    // min-width: 175px;
    // max-width: 300px;
    // max-height: 175px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 25px;


    font-size: .8em;
  }

  &:hover {
    cursor: pointer;

    .albumInfo {
      animation: tooltipFadeIn 0.15s;
      display: block;
    }
  }
}

.albumInfo_content {
  background-color: $color-secondary;
  border: 1px solid #404040;
  border-radius: 4px;
  padding: 8px 12px;
  width: 50vw;
  min-width: 125px;
  max-width: 175px;
  text-align: left;

  .link-btn {
    margin: 0 auto;

    p {
      margin-block: 0;
    }
  }


  @media screen and (max-width: $tablet) {
    max-width: 150px;
  }
}



@media (hover: hover) {
  .albumTile:hover {
    .albumInfo {
      animation: tooltipFadeIn 0.15s;
      display: block;
    }
  }
}


/* Show the tooltip text when you mouse over the tooltip container */